import styles from './style.module.scss'

const PassInfomation = () => {
  return (
    <div className={styles.passInfomation}>
      <div className={styles.passInfomation__Wrapper}>
        <p className={styles.passInfomation__Text}>
          パス発行申請後、承認までに通常2-3日お時間を頂いております。ご旅程が決まり次第お早めに発行申請をお願いいたします。
        </p>
      </div>
    </div>
  )
}

export { PassInfomation }

import { ReactNode } from 'react';

import styles from './style.module.scss';

type props = {
  children: ReactNode
  className?: string
  mtNone?: boolean
}

const Container = ({ children, className, mtNone }: props) => {
  return (
    <div className={`${styles.container} ${className} ${mtNone && styles.containerIsMtNone}`}>{children}</div>
  )
}
export { Container }

import styles from './style.module.scss'
import { FC } from 'react'

const PickupLabel: FC = () => {
  return (
    <p className={styles.pickupLabel}>人気の特典</p>
  )
}

export default PickupLabel

import { Routes } from 'src/utils/routes'
import { WafFriendlyPass } from 'src/types/waf-friendly-pass'
import Image from 'next/image'
import styles from './style.module.scss'
import Link from 'next/link'
import { useMediaQuery } from 'src/utils/useMediaQuery'
import { useEffect, useState } from 'react'
import { wpApiUrl } from 'src/utils/wpApiUrl'
import { fetchWithCache } from 'src/utils/fetchWithCache'
import PickupLabel from 'src/components/pickupLabel'

type ImageSet = {
  width: number
  height: number
}

type Props = {
  service: WafFriendlyPass.service
  single?: boolean
  grid?: boolean
}

type SpotSet = {
  spot: WafFriendlyPass.spot
}

export default function ServiceCard({ service, single, grid }: Props) {
  // state
  const [featureState, setFeatureState] = useState(false)

  // set
  const [features, setFeatures] = useState<WafFriendlyPass.taxonomy[]>([])
  const [spotName, setSpotName] = useState('')
  const [spotImage, setSpotImage] = useState('')
  const [spotArea, setSpotArea] = useState('')
  const [spotType, setSpotType] = useState('')

  useEffect(() => {
    const f = async () => {
      await new Promise((r) => setTimeout(r, 1000))
      const spot = (await fetchWithCache(
        `${wpApiUrl.spot}/${service.acf.spot[0]}?_fields=id,acf.images,acf.name,acf.taxonomy`
      )) as SpotSet['spot']
      setSpotName(spot.acf.name ? spot.acf.name : '')
      setSpotImage(
        spot.acf.images[0] ? spot.acf.images[0].image.sizes.medium : ''
      )
      setSpotArea(spot.acf.taxonomy.area.name)
      setSpotType(spot.acf.taxonomy.type.name)
    }
    f()
  }, [])

  useEffect(() => {
    setFeatureState(service.acf.taxonomy.feature.length)
    setFeatures(featureState ? service.acf.taxonomy.feature : [])
  }, [features, featureState])

  const mq = useMediaQuery()
  const imageSet: ImageSet = {
    width: mq.isMoblie ? 250 : 180,
    height: mq.isMoblie ? 180 : 130,
  }

  return (
    <article
      className={
        grid
          ? `${styles.serviceCard} ${styles.isGrid} `
          : `${styles.serviceCard}`
      }
    >
      <Link href={`${Routes.SERVICE}/${service.id}`} passHref>
        <a>
          <div data-size={single} className={styles.serviceCardImageStack}>
            <p className={styles.serviceCardTax}>
              {spotArea} / {spotType}
            </p>
            <div className={styles.serviceCardImage}>
              <Image
                src={service.acf.image.sizes.large}
                layout="responsive"
                width={imageSet.width}
                height={imageSet.height}
                alt={service.acf.image.title}
              />
            </div>
            {service.acf.pickup && (
              <div className={styles.serviceCardPickup}>
                <PickupLabel />
              </div>
            )}
          </div>
          <h2 className={styles.serviceCardHeading}>{service.acf.name}</h2>
          <p className={styles.serviceCardPrice}>{service.acf.price}</p>
          <div className={styles.serviceCardSpot}>
            <div className={styles.serviceCardSpotImage}>
              {spotImage && (
                <Image
                  src={spotImage}
                  layout="responsive"
                  width={24}
                  height={24}
                  alt={spotName}
                  priority={true}
                />
              )}
            </div>
            <p className={styles.serviceCardSpotName}>{spotName}</p>
          </div>
        </a>
      </Link>
    </article>
  )
}
